.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none;
  }

  .md\:pl-64 {
    padding-left: 16rem;
  }

  .md\:translate-x-0 {
    transform: translateX(0);
  }
}

.pagination {
  display: flex;
  gap: 8px;
}

.page-number {
  background-color: #f1f1f1;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-number:hover {
  background-color: #ddd;
}

.page-number.active {
  background-color: #555;
  color: #fff;
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}
