@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Noto Sans Thai', sans-serif;
}

code {
  font-family: 'Noto Sans Thai', sans-serif;
}
a {
  font-size: 14px;
}

.swal2-container {
  z-index: 10000 !important;
}

